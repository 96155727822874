.App {
  /* background-color: #282c34; */
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* font-size: calc(12px + 2vmin); */
  font-size: 14px;
  /* color: white; */
  /* width: 100%;  */
}

.App Button.link {
  background-color: #ffffff00;
  color: #047D95;
}

.App Button.wide {
  width: 100%;
}

.error {
  color: red;
}

.disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

/* This was pulled from https://github.com/twbs/icons/issues/82 */
.bi {
  vertical-align: -0.125em;
  margin-bottom: calc(-0.125em / 2);
}

/* This was pulled from https://github.com/twbs/icons/issues/82 */
/* Make icons slightly larger (aesthetically) in input group prepend/append. */
.input-group {
  .input-group-text {
    .bi {
      transform: scale(1.5);
    }
  }
}

/* This was pulled from https://github.com/twbs/icons/issues/82 */
/* Make icons slightly larger (aesthetically) in buttons and dropdowns. */
.btn,
.dropdown {
  .bi {
    transform: scale(1.5);
    /* reset the bottom margin from above */
    margin-bottom: 0;
  }
}

img.profile {
  height: 192px;
}

img.mini-profile {
  height: 30px;
}

.telaport-yellow-bg {
  background-color: #CCCE02;
  /* Replace with your desired hex value */
}