img.logo {
  width: 3rem;
  height: 3rem;
  --bs-gutter-x: 0;
}

img.spin {
  animation-name: spin360;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}