h2,
h3,
h4 {
    font-family: 'Josefin Sans', sans-serif;
    text-indent: 0px;
}

.discord-logo-container {
    text-align: center;
    cursor: pointer;
}

.discord-logo {
    max-width: 100px;
    height: auto;
}