.card-img-top-fit {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container without stretching */
  }

h1 {
    font-family: 'Josefin Sans', sans-serif;
}

h2, h3, h4 {
    font-family: 'Josefin Sans', sans-serif;
}

.padded-hero-row {
    padding-left: 15px;
    padding-right: 15px;
}

.card-body-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .team-list {
    flex-grow: 1; /* Grow to take available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space evenly */
    padding: 0; /* Reset default padding */
    list-style-type: none; /* Remove default bullets */
    margin: 0; /* Reset default margin */
  }
  
  .team-list li {
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .team-list li:last-child {
    margin-bottom: 0; /* Remove margin from the last item */
  }
  
  .checkmark-list {
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    justify-content: space-between; /* Evenly space the items vertically */
    height: 100%; /* Make sure the list fills the height of its container */
}


.checkmark-list li {
    list-style-type: none; /* Removes default bullets */
    position: relative;
    padding-left: 35px; /* Adjust as needed */
    margin-bottom: 10px; /* Adjust as needed */
}

.checkmark-list li::before {
    /* content: '✔'; Checkmark */
    position: absolute;
    left: 0;
    top: 0;
    color: rgba(14, 182, 233, 0.667);
    /* background-color: rgba(14, 182, 233, 0.667); Example: light grey background */
    width: 30px; /* Size of the square */
    height: 30px;
    text-align: center;
    line-height: 30px; /* Align the checkmark vertically */
    border-radius: 4px; /* Optional: if you want rounded corners */
}