.image-crop {
    overflow: hidden;
    width: 100%; /* width of the card */
    height: 0;
    padding-top: 100%; /* height equal to the width for a square */
    position: relative;
  }
  
  .image-crop .card-img-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will ensure the image covers the square area and is cropped to fit */
  }

  .cursor-pointer {
    cursor: pointer;
  }