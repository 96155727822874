.welcome {
  font-size: larger;
  font-weight: bold;
}

.rightColumn {
  /* Makes it a flex container */
  display: flex;
  /* Stacks children vertically */
  flex-direction: column;
  /* Aligns children vertically in the center */
  justify-content: center;
  /* Aligns children horizontally in the center */
  align-items: center;
  /* height: 100%; Takes full height of the parent */
}

.full-height-container {
  /* This makes the container take the full height of the viewport */
  height: 100vh;
}

.full-height-row {
  /* Ensures the row behaves as a flex container */
  display: flex;
  /* Stretches the row to the full height of its parent */
  height: 100%;
}

.rightColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Ensures the column takes the full height of the row */
  height: 100%;
}

.telaport-yellow-bg blockquote {
  font-family: 'Josefin Sans', sans-serif;
}

.required-indicator {
  color: red;
}

/* In your CSS file (e.g., App.css) */
.figcaption-large {
  font-size: 1.5rem;
  /* Adjust the font size as needed */
}

@keyframes spin360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}