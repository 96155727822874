li {
    padding-bottom: 1rem;
}

.josefin-sans {
    font-family: 'Josefin Sans';
}

.checkmark-list {
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    justify-content: space-between; /* Evenly space the items vertically */
    height: 100%; /* Make sure the list fills the height of its container */
}


.checkmark-list li {
    list-style-type: none; /* Removes default bullets */
    position: relative;
    padding-left: 35px; /* Adjust as needed */
    margin-bottom: 10px; /* Adjust as needed */
}

.checkmark-list li::before {
    content: '✔' !important; /* Checkmark */
    position: absolute;
    left: 0;
    top: 0;
    color: blue !important;
    background-color: rgba(14, 182, 233, 0.667); /* Example: light grey background */
    width: 30px; /* Size of the square */
    height: 30px;
    text-align: center;
    line-height: 30px; /* Align the checkmark vertically */
    border-radius: 4px; /* Optional: if you want rounded corners */
}