.h2,
h2 {
    font-family: 'Josefin Sans', sans-serif;
}

.Navbar {
    font-family: 'Josefin Sans', sans-serif;
}

Nav {
    font-family: 'Josefin Sans';
}

.custom-tooltip {
    position: absolute;
    /* Adjust based on your navbar's height */
    top: 50px;
    /* Adjust as needed */
    right: 15px;
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 1000;
}

.custom-nav-link-button {
    /* Use Bootstrap's primary color */
    background-color: var(--bs-primary);
    /* Text color */
    color: white;
    /* Remove border if present */
    border: none;
}

.custom-nav-link-button:hover {
    /* Slightly darker on hover */
    background-color: darken(var(--bs-primary), 10%);
}

.company {
    font-size: xx-large;
}

sup.company {
    font-size: x-large;
    position: relative;
    top: -18px;
    right: -2px;
}