span.thumbnail {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    height: 100%;
    width: fit-content;
}

.img-thumbnail {
    /* This ensures there's no space below the image */
    display: inline-block;
    height: 100%;
    width: auto;
}

button.close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0.25rem 0.5rem;
    font-size: 1.25rem;
    line-height: 1;
    /* Ensures the button is above the image */
    z-index: 10;
}