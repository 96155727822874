/* In your CSS file or a CSS module */
.banner {
  background-image: url('https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/Logo4_tm.pnghttps://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/Banner_Logo.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* Adjust the height as needed */
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: yellow;
  /* Adjust the font size as needed */
  font-size: 24px;
  text-align: center;
}

@media (min-width: 500px) {
  .banner {
    background-size: 50%;
  }

}